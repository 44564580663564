<template>
  <div>
    <a-badge :color="color"></a-badge>
    <span :style="{ color: color }">{{ $t(`ip_subnet_status.${status}`) }}</span>
  </div>
</template>

<script>
export default {
  name: 'ScanStatusBadge',
  props: {
    status: { type: String, default: 'not_started' }
  },
  computed: {
    color () {
      switch (this.status) {
        case 'pending':
          return '#fdd83c'
        case 'running':
          return '#5b8ff9'
        case 'success':
          return '#52c41a'
        case 'failure':
          return '#f5222d'
        default:
          return '#d9d9d9'
      }
    }
  }
}
</script>
