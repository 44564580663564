<template>
  <div>
    <div style="margin-bottom: 16px;">
      <a-page-header title="IP 子网" :ghost="false" :sub-title="detail.name">
        <template slot="tags">
          <scan-status-badge :status="detail.scan_status"></scan-status-badge>
        </template>
        <template v-if="permission.update" slot="extra">
          <a @click="$refs.updateForm.show(detail.id)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <update-form ref="updateForm" @ok="fetch"></update-form>
        </template>

        <a-descriptions :column="{ xs: 1, sm: 2, xl: 3, xxl: 4 }">
          <a-descriptions-item label="子网掩码">{{ detail.netmask }}</a-descriptions-item>
          <a-descriptions-item label="子网容量">{{ detail.capacity }}</a-descriptions-item>
          <a-descriptions-item label="描述">{{ detail.description }}</a-descriptions-item>
        </a-descriptions>
      </a-page-header>
    </div>

    <a-card :bordered="false">
      <div>
        <div style="margin-bottom: 16px; text-align: right;">
          <a-space style="user-select: none;">
            <a-badge color="#52c41a" text="在线"></a-badge>
            <a-badge color="#eeeeee" text="离线"></a-badge>
          </a-space>
        </div>
      </div>

      <a-spin :spinning="spinning" style="width: 100%;"></a-spin>
      <span v-for="ipAddress in ipAddresses" :key="ipAddress.id" style="margin: 4px; width: 32px; height: 32px; display: inline-block;">
        <a-tooltip>
          <template slot="title">
            <div>
              <a-space>
                <span>IP 地址: </span>
                <span>{{ ipAddress.name }}</span>
              </a-space>
            </div>
            <div>
              <a-space>
                <span>IP 状态: </span>
                <boolean-status-badge false-name="离线" true-name="在线" :value="ipAddress.active"></boolean-status-badge>
              </a-space>
            </div>
            <div v-if="ipAddress.host">
              <a-space style="align-items: baseline;">
                <span style="white-space: nowrap;">主机: </span>
                <span>{{ ipAddress.host.name }}</span>
              </a-space>
            </div>
          </template>

          <a-card :bordered="false" :body-style="{ background: ipAddress.active ? '#52c41a' : '#eeeeee', padding: 0, height: '32px' }">
            <div style="line-height: 32px; text-align: center;">
              <div v-if="ipAddress.active && ipAddress.host">
                <router-link :to="{ name: 'HostDetail', params: { id: ipAddress.host.id } }">
                  <svg-icon icon-name="host" class-name="ip-address-icon"></svg-icon>
                </router-link>
              </div>
              <div v-else style="color: #ffffff; user-select: none;">{{ ipAddress.name.split('.')[3] }}</div>
            </div>
          </a-card>
        </a-tooltip>
      </span>
    </a-card>
  </div>
</template>

<script>
import { getIPSubnet, getIPSubnetIPAddressList } from '@/api/ip-subnet'
import BooleanStatusBadge from '@/components/badge/BooleanStatusBadge'
import { hasPermission } from '@/utils'
import ScanStatusBadge from './modules/ScanStatusBadge'
import UpdateForm from './modules/UpdateForm'

export default {
  name: 'IPSubnetDetail',
  components: {
    BooleanStatusBadge,
    ScanStatusBadge,
    UpdateForm
  },
  data () {
    return {
      detail: {},
      spinning: false,
      ipAddresses: []
    }
  },
  computed: {
    permission () {
      return {
        update: hasPermission('ip_subnet.update')
      }
    }
  },
  mounted () {
    this.fetch()
    this.spinning = true
    getIPSubnetIPAddressList(this.$route.params.id).then(res => {
      this.ipAddresses = res.data.data
    }).finally(() => {
      this.spinning = false
    })
  },
  methods: {
    fetch () {
      getIPSubnet(this.$route.params.id).then(res => {
        this.detail = res.data
      })
    }
  }
}
</script>

<style lang="less">
.ip-address-icon {
  height: 12px;
  width: 12px;
  color: #ffffff;
}
</style>
