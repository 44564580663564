<template>
  <a-modal :confirm-loading="loading" title="编辑 IP 子网" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="描述" prop="description">
        <a-input v-model="form.description" :max-length="128" placeholder="请输入 IP 子网描述"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getIPSubnet, updateIPSubnet } from '@/api/ip-subnet'

export default {
  name: 'UpdateForm',
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        description: ''
      },
      id: ''
    }
  },
  methods: {
    show (id) {
      this.id = id
      getIPSubnet(id).then(res => {
        const data = res.data
        this.form.description = data.description
      }).finally(() => {
        this.visible = true
      })
    },
    reset () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          updateIPSubnet(this.id, this.form).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
